<template>
<div>
    <div id="upload-form">

    <div id="uploader-wrapper">
        <div id="uploader"></div>
        <div id="status-bar"></div>
    </div>

    <div id="current-file" v-if="currentFile.meta">
        <div id="file-name">{{currentFile.meta.name}}</div>
    </div>






    </div>




</div>
</template>

<script>
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import Dashboard from '@uppy/dashboard'
// import StatusBar from '@uppy/status-bar'
//import DragDrop from '@uppy/drag-drop'
import Zoom from '@uppy/zoom'
//import GoogleDrive from '@uppy/google-drive'
import uploader from './../uploader'

// import Uppy from '@uppy/core'
//import { Dashboard } from '@uppy/vue'

export default {
    data () {
        return {
            currentFile: {}
        }
    },
    mounted () {
        uploader.use(Dashboard, {
            id: 'uploader',
            target: '#uploader',
            inline: true,
            companionUrl: 'https://upload-dev.saylient.io',
        //    thumbnailWidth: 280,
        //    // defaultTabIcon: defaultTabIcon,
        //     showLinkToFileUploadResult: false,
        //     showProgressDetails: true,
        //     hideUploadButton: false,
        //     hideRetryButton: false,
        //     hidePauseResumeButton: true,
        //     hideCancelButton: false,
        //     hideProgressAfterFinish: false,
        //     // doneButtonHandler: () => {
        //     //     this.uppy.reset()
        //     //     this.requestCloseModal()
        //     // },
        //     note: null,
        //     closeModalOnClickOutside: false,
        //     closeAfterFinish: false,
        //     disableStatusBar: false,
        //     disableInformer: false,
        //     disableThumbnailGenerator: false,
        //    // disablePageScrollWhenModalOpen: true,
        //    // animateOpenClose: true,
        //    // fileManagerSelectionType: 'files',
        //     proudlyDisplayPoweredByUppy: false,
        //     // onRequestCloseModal: () => this.closeModal(),
        //     showSelectedFiles: true,
        //     showRemoveButtonAfterComplete: false,
        //   //  locale: defaultLocale,
        //     browserBackButtonClose: false,
        //     theme: 'light',
        //     autoOpenFileEditor: false
            height: 350,
            width: 700,

        })

        uploader.on('file-added', (file) => {
            // console.log('Added file to this dashboard', file.data)
            // console.log(file)
          //  console.log("check uploads")

            this.currentFile = file

            // check file is there - if it is - hide the dashboard
            // if its uploader -> do the file checking for duration
        })

        // uploader.use(StatusBar, {
        //     id: 'StatusBar',
        //     target: '#status-bar',
        //     hideAfterFinish: true,
        //     showProgressDetails: false,
        //     hideUploadButton: false,
        //     hideRetryButton: false,
        //     hidePauseResumeButton: false,
        //     hideCancelButton: false,
        //     doneButtonHandler: null,
        //     locale: {}
        // })

        // uploader.use(DragDrop, {
        //     width: '100%',
        //     height: '100%',
        //     note: "where does this note show up"
        // })

        // uploader.use(GoogleDrive, {
        //     target: Dashboard,
        //     companionUrl: 'http://localhost:3020'
        // })

        uploader.use(Zoom, {
            target: Dashboard,
            companionUrl: 'http://localhost:3020'
        })

        // console.log(uploader)
    }
}
</script>

<style lang="scss" scoped>

#uploader-wrapper {
    width: 80%;
    margin: 0 auto;
    border: 1px solid red;
}

</style>