import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import DefaultStore from '@uppy/store-default'
import requests from './requests'
import store from './store.js'
import EventBus from './eventBus'

const uploader = Uppy({
          debug: false,
          id: 'uploader',
          autoProceed: false,
          store: new DefaultStore(),
          restrictions: {
              // maxFileSize: 500 * 1024 * 1024, //500MB file limit - conservative limit for the lambda size constraints.
              maxFileSize: 1000 * 1024 * 1024, //1GB file limit - conservative limit for the lambda size constraints.
              maxNumberOfFiles: 1,
              minNumberOfFiles: 1,
              allowedFileTypes: [
                          'audio/mp3',
                          'video/mp4',
                          // 'video/*',
                          // 'audio/*',
                          'audio/mpeg',
                          'audio/x-m4a', // What uppy returns for m4a, from what i've seen
                          'audio/m4a',
                          'audio/mp4',
                          'audio/webm',
                          'video/webm',
                          'video/mpeg',
                          'audio/wav',
                          'audio/x-wav',
                          'video/mkv',
                          'audio/x-mkv',
                          // 'video/avi',
                          'video/x-matroska',
                          'video/quicktime',
                ]
          },
          onBeforeUpload: (files) => {

            const updatedFiles = Object.assign({}, files)

            Object.keys(updatedFiles).forEach(fileId => {

              let fileExt = updatedFiles[fileId].extension

              if (!updatedFiles[fileId].extension) {
                fileExt = updatedFiles[fileId].type.split('/').pop()
              }

              updatedFiles[fileId].name =
              updatedFiles[fileId].meta.UserID +
              '.' + updatedFiles[fileId].meta.CollectionID +
              '.' + updatedFiles[fileId].meta.RecordingID +
              '.' + fileExt
              })

            return updatedFiles
          }
          })
          .use(AwsS3Multipart, {
            limit: 4,
            companionUrl: "http://upload-dev.saylient.io",
          })
          .on('upload-success', (file) => {
              store.dispatch('INCREMENT_UPLOADS', "minus")

              let duration = -file.meta.Duration
              if (!file.meta.Transcribe) {
                duration = 0
              }

              // update pending duration and bytes limit
              store.dispatch('INCREMENT_PENDING', {
                duration: duration,
                size: -file.size,
              })

              requests.uploadComplete(file.meta.CollectionID,file.meta.RecordingID).then(
                EventBus.$emit('ACCOUNT_CHANGE'),
                EventBus.$emit('UPLOAD_SUCCESS', file.name),
                EventBus.$emit('UPDATE_REC_PROCESS_STATUS')
              )

          })
          .on('upload', () => {

              store.dispatch('INCREMENT_UPLOADS', "add")
              EventBus.$emit("SHOW_MESSAGE", "Uploading File...")

            })
          .on('upload-progress', (file, progress) => {

              store.dispatch('UPDATE_UPLOADS', {file: file, progress: progress} )

    })

export default uploader;
